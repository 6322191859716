import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const ScreenshotsFilters = lazy(() => import('./ScreenshotsFilters'));

class ScreenshotFiltersComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['hideCheckbox', 'screenshots'];
    }

    render() {
        const hideCheckbox = !!this.jsonProp('hideCheckbox'); // Option used within Editor > Screenshot panel

        const onBulkActionSuccess = () => {
            const event = new CustomEvent('@screenshots/bulk-action/success');
            this.dispatchEvent(event);
        };

        return (
            <AppProviders>
                <ScreenshotsFilters hideCheckbox={hideCheckbox} onBulkActionSuccess={onBulkActionSuccess} />
            </AppProviders>
        );
    }
}

customElements.define('screenshot-filters', ScreenshotFiltersComponent);
