import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));

const AiModelSettingWrapper = lazy(() => import('./AiModelSettingWrapper'));

class AiModelSettingComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <AiModelSettingWrapper />
            </RootProviders>
        );
    }
}

customElements.define('ai-model-setting', AiModelSettingComponent);
