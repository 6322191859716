// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.POpvTA_xH0kegC2q {
    height: 100%;
    padding: 0 var(--lok-spacing-7);
}

.CJCmOduwhOsdpRMl {
    /* This has to be hardcoded here to match Metabase's iframe color scheme. */
    background-color: #2e353b;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Reporting/components/PageContainer/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,2EAA2E;IAC3E,yBAAyB;AAC7B","sourcesContent":[".pageContainer {\n    height: 100%;\n    padding: 0 var(--lok-spacing-7);\n}\n\n.darkBackground {\n    /* This has to be hardcoded here to match Metabase's iframe color scheme. */\n    background-color: #2e353b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `POpvTA_xH0kegC2q`,
	"darkBackground": `CJCmOduwhOsdpRMl`
};
export default ___CSS_LOADER_EXPORT___;
