import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const EditorOptionsContainer = lazy(() => import('.'));

declare global {
    interface HTMLElementTagNameMap {
        'editor-options': EditorOptionsComponent;
    }
}

class EditorOptionsComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'project-type',
            'enum-value',
            'diff',
            'display-key-reference-as-text',
            'focus-mode',
            'focus-mode-loading',
            'screenshots-active',
            'show-diff-button',
            'show-focus-button',
            'show-key-reference-button',
            'show-screenshots',
            'slider-remember',
            'spell-checking',
            'wrap-placeholders',
        ];
    }

    render() {
        const diff = this.prop('diff') === 'true';
        const displayKeyReferenceAsText = this.prop('display-key-reference-as-text') === 'true';
        const focusMode = this.prop('focus-mode') === 'true';
        const focusModeLoading = this.prop('focus-mode-loading') === 'true';
        const screenshotsActive = this.prop('screenshots-active') === 'true';
        const showDiffButton = this.prop('show-diff-button') === 'true';
        const showFocusButton = this.prop('show-focus-button') === 'true';
        const showKeyReferenceButton = this.prop('show-key-reference-button') === 'true';
        const showScreenshots = this.prop('show-screenshots') === 'true';
        const sliderRemember = this.prop('slider-remember') === 'true';
        const spellChecking = this.prop('spell-checking') === 'true';
        const wrapPlaceholders = this.prop('wrap-placeholders') === 'true';
        const showSliderRememberButton = this.prop('show-slider-remember-button') === 'true';

        return (
            <RootProviders>
                <EditorOptionsContainer
                    diff={diff}
                    displayKeyReferenceAsText={displayKeyReferenceAsText}
                    focusMode={focusMode}
                    focusModeLoading={focusModeLoading}
                    screenshotsActive={screenshotsActive}
                    showDiffButton={showDiffButton}
                    showFocusButton={showFocusButton}
                    showKeyReferenceButton={showKeyReferenceButton}
                    showScreenshots={showScreenshots}
                    sliderRemember={sliderRemember}
                    spellChecking={spellChecking}
                    wrapPlaceholders={wrapPlaceholders}
                    showSliderRememberButton={showSliderRememberButton}
                />
            </RootProviders>
        );
    }
}

customElements.define('editor-options', EditorOptionsComponent);
