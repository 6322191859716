import { type PropsWithChildren, createContext, useContext, useMemo } from 'react';

import useSelector from '@app/hooks/useSelector';
import { getProjectRights } from '@app/selectors';

// TODO: Remove context after permissions clean up
//   This context was previously used to branch-return legacy and granular permissions.
//   Now the branching is removed and the context is not necessary anymore, so it can be removed.

type ProjectRights = ReturnType<typeof getProjectRights>;

type GlossaryPermissionsContext = {
    usesSharedGlossary: boolean;
    canReadTerms: boolean;
    canCreateTerms: boolean;
    canUpdateTerms: boolean;
    canDownloadCsv: boolean;
    canExtractTerms: boolean;
    canDeleteTerm: boolean;
    canDeleteAllTerms: boolean;
    canUploadCsv: boolean;
};

const glossaryPermissions = (usesSharedGlossary: boolean, projectRights: ProjectRights): GlossaryPermissionsContext => {
    const hasManageGlossaryPermission = projectRights.manageGlossary === true;
    const hasDeleteGlossaryPermission = projectRights.deleteGlossary === true;

    return {
        usesSharedGlossary,
        canReadTerms: true,
        canDownloadCsv: true,
        canCreateTerms: hasManageGlossaryPermission,
        canUpdateTerms: hasManageGlossaryPermission,
        canExtractTerms: hasManageGlossaryPermission,
        canDeleteTerm: !usesSharedGlossary && hasManageGlossaryPermission && hasDeleteGlossaryPermission,
        canDeleteAllTerms: !usesSharedGlossary && hasManageGlossaryPermission && hasDeleteGlossaryPermission,
        canUploadCsv: hasManageGlossaryPermission,
    };
};

const glossaryPermissionsContext = createContext<GlossaryPermissionsContext | null>(null);

type GlossaryPermissionsContextProviderProps = PropsWithChildren<{
    usesSharedGlossary: boolean;
}>;

export const GlossaryPermissionsContextProvider = ({
    usesSharedGlossary,
    children,
}: GlossaryPermissionsContextProviderProps) => {
    const projectRights = useSelector(getProjectRights);

    const value = useMemo(
        () => glossaryPermissions(usesSharedGlossary, projectRights),
        [usesSharedGlossary, projectRights],
    );

    return <glossaryPermissionsContext.Provider value={value}>{children}</glossaryPermissionsContext.Provider>;
};

export const useGlossaryPermissionsContext = () => {
    const context = useContext(glossaryPermissionsContext);

    if (!context) {
        throw new Error('useGlossaryPermissionsContext must be used within a GlossaryPermissionsContextProvider');
    }

    return context;
};
