import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { Project } from '@app/features/team/model/projects';
import type { AddContributorsModalPage } from './types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const AddContributorsModal = lazy(() => import('.'));

class AddContributorsWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'page',
            'seats-limit',
            'seats-taken',
            'invited-seats',
            'grace-period-seats',
            'extra-seats-allowed',
            'is-provider-alpha',
            'team-members',
            'project',
            'groups',
            'is-groups-enabled',
            'team-name',
            'plan-name',
        ];
    }

    render() {
        const planName = this.prop('plan-name');
        const teamName = this.prop('team-name');
        const page = this.prop('page') as AddContributorsModalPage;
        const seatsLimit = Number(this.prop('seats-limit'));
        const seatsTaken = Number(this.prop('seats-taken'));
        const invitedSeats = Number(this.prop('invited-seats'));
        const gracePeriodSeats = Number(this.prop('grace-period-seats'));
        const isExtraSeatsAllowed = this.jsonProp('extra-seats-allowed') === 1;
        const isProviderAlpha = this.jsonProp('is-provider-alpha') === 1;

        const isGroupsEnabled = this.getAttribute('is-groups-enabled') === 'true';

        const optionalActiveProject = this.getAttribute('project') ?? undefined;
        const activeProject = optionalActiveProject ? (JSON.parse(optionalActiveProject) as Project) : undefined;

        const optionalTeamMembersAttr = this.getAttribute('team-members') ?? undefined;
        const teamMembers = optionalTeamMembersAttr ? (JSON.parse(optionalTeamMembersAttr) as []) : undefined;

        const optionalGroupsAttr = this.getAttribute('groups') ?? undefined;
        const groups = optionalGroupsAttr ? (JSON.parse(optionalGroupsAttr) as []) : undefined;

        return (
            <RootProviders>
                <AddContributorsModal
                    planName={planName}
                    teamName={teamName}
                    page={page}
                    seatsLimit={seatsLimit}
                    seatsTaken={seatsTaken}
                    invitedSeats={invitedSeats}
                    gracePeriodSeats={gracePeriodSeats}
                    isExtraSeatsAllowed={isExtraSeatsAllowed}
                    isProviderAlpha={isProviderAlpha}
                    activeProject={activeProject}
                    teamMembers={teamMembers}
                    groups={groups}
                    isGroupsEnabled={isGroupsEnabled}
                />
            </RootProviders>
        );
    }
}

customElements.define('add-contributors-modal', AddContributorsWebComponent);
