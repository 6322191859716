import { KeyEditMode } from '@app/components/KeyTitle/constants';
import type { KeyData, RawProjectData, UserData } from '@app/components/KeyTitle/types';
import { ProjectType } from '@app/features/project/types/project';
import { ReactWebComponent } from '@app/utils/web-components';
import { lazy } from 'react';

const KeyTitle = lazy(() => import('./components/KeyTitleContainer'));

const KeyTitleWebComponent = class KeyTitleWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['project', 'akey', 'user'];
    }
    render() {
        const rawProject: RawProjectData | null = this.jsonProp('project');
        const akey: KeyData | null = this.jsonProp('akey');
        const user: UserData | null = this.jsonProp('user');

        if (!rawProject || !akey || !user) {
            return;
        }

        const project = {
            keyEditMode: rawProject.keyEditMode,
            areKeysEditable:
                [ProjectType.pagedDocuments, ProjectType.contentIntegration].indexOf(rawProject.type) === -1 &&
                user.permissions?.manage_keys === 1 &&
                rawProject.keyEditMode !== KeyEditMode.Disabled,
            areKeysPerDevice:
                [ProjectType.pagedDocuments, ProjectType.contentIntegration].indexOf(rawProject.type) === -1 &&
                rawProject.perDevice,
            shouldShowKeyTaskFilename: !!(
                akey.deviceFileNames.other &&
                [ProjectType.pagedDocuments, ProjectType.contentIntegration].indexOf(rawProject.type) !== -1
            ),
            type: rawProject.type,
        };

        return <KeyTitle project={project} akey={akey} user={user} />;
    }
};

customElements.define('key-title', KeyTitleWebComponent);
