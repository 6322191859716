import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { ProjectLanguage } from '../..';
import { GlossaryPermissionsContextProvider } from '../../context/GlossaryPermissionsContext';
import type { NewTermModalOrigin } from '../../types';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const NewTermModal = lazy(() => import('.'));

class NewTermsGlossaryComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['selected-text', 'project-id', 'project-languages', 'opened', 'origin', 'shared-glossary'];
    }

    render() {
        const selectedText = this.getAttribute('selected-text') || '';
        const projectId = this.getAttribute('project-id') || '';
        const origin = (this.getAttribute('origin') as NewTermModalOrigin) || '';
        const opened = this.getAttribute('opened') === 'true';
        const projectLanguages: ProjectLanguage[] = JSON.parse(this.getAttribute('project-languages') || 'null');
        const usesSharedGlossary = this.prop('shared-glossary') === 'true';

        return (
            <AppProviders>
                <GlossaryPermissionsContextProvider usesSharedGlossary={usesSharedGlossary}>
                    {opened && (
                        <NewTermModal
                            initialText={selectedText}
                            projectId={projectId}
                            projectLanguages={projectLanguages}
                            origin={origin}
                            onClose={() => {
                                const event = new Event('onglossarytermadded');
                                this.dispatchEvent(event);
                            }}
                            isOpen={opened}
                        />
                    )}
                </GlossaryPermissionsContextProvider>
            </AppProviders>
        );
    }
}

customElements.define('editor-new-terms-glossary', NewTermsGlossaryComponent);
