export enum DeviceName {
    Ios = 'ios',
    Web = 'web',
    Android = 'android',
    Other = 'other',
}

export enum KeyEditMode {
    Disabled = '0',
    Enabled = '1',
    DescriptionAndLimit = '2',
}
